import React, { useState } from "react";
import { PlusIcon, MinusIcon } from "@heroicons/react/24/outline"; // Import Heroicons

const CondensedTable = ({ journalData, sortOrder, onClick }) => {
  const { appointments, clientNotes, invoices, payments } = journalData;

  console.log("this is journaldata in condesnsedtale", journalData);

  // Combine all data into a single array with a type key
  const combinedData = [
    ...appointments.map((appointment) => ({
      type: "Appointment",
      id: appointment._id,
      createdAt: appointment.createdAt,
      createdBy: appointment.createdBy
        ? `${appointment.createdBy.firstName} ${appointment.createdBy.lastName}`
        : "N/A",
      title: appointment.title,
      status: appointment.status.join(", "),
      fullAppointment: appointment, // Keep the full appointment object
    })),
    ...clientNotes.map((note) => ({
      type: "Client Note",
      id: note._id,
      createdAt: note.createdAt,
      createdBy: note.createdBy?.firstName + " " + note.createdBy?.lastName,
      content: note.history.map((h) => h.content).join(", "),
    })),
    ...invoices.map((invoice) => ({
      type: "Invoice",
      id: invoice._id,
      createdAt: invoice.createdAt,
      createdBy:
        invoice.clinician?.firstName + " " + invoice.clinician?.lastName,
      invoiceId: invoice.invoiceId,
      amount: invoice.amount,
      status: invoice.status,
    })),
    ...payments.map((payment) => ({
      type: "Payment",
      id: payment._id,
      createdAt: payment.paymentDate,
      createdBy:
        payment.invoiceId?.createdBy?.firstName +
        " " +
        payment.invoiceId?.createdBy?.lastName,
      invoiceId: payment.invoiceId.invoiceId,
      amount: payment.amount,
      status: payment.voided ? "Voided" : "Completed",
    })),
  ];

  // Sort the combined data based on the sortOrder
  const sortedData = combinedData.sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return sortOrder === "Oldest first" ? dateA - dateB : dateB - dateA;
  });

  // State to manage expanded rows
  const [expandedRows, setExpandedRows] = useState(
    sortedData.map(() => false) // Default all rows to expanded
  );

  const toggleRow = (index) => {
    const newExpandedRows = [...expandedRows];
    newExpandedRows[index] = !newExpandedRows[index];
    setExpandedRows(newExpandedRows);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold text-gray-900">
            Client Journal
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A history of all client associated actions.
          </p>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th className="whitespace-nowrap w-[50px] py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    {/* Empty header for icons */}
                  </th>
                  <th className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Type
                  </th>
                  <th className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Created At
                  </th>
                  <th className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Created By
                  </th>
                  <th className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Status
                  </th>
                  <th className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Details
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {sortedData.map((item, index) => (
                  <React.Fragment key={item.id}>
                    <tr>
                      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                        <button
                          onClick={() => toggleRow(index)}
                          className="focus:outline-none">
                          {expandedRows[index] ? (
                            <MinusIcon className="h-5 w-5 text-gray-500" />
                          ) : (
                            <PlusIcon className="h-5 w-5 text-gray-500" />
                          )}
                        </button>
                      </td>
                      <td
                        onClick={() =>
                          item.type === "Appointment" &&
                          onClick(item.fullAppointment)
                        }
                        className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0 cursor-pointer">
                        <div className="overflow-x-auto">{item.type}</div>
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        <div className="overflow-x-auto">
                          {new Date(item.createdAt).toLocaleString()}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        <div className="overflow-x-auto">
                          {item.createdBy || "N/A"}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        <div className="overflow-x-auto max-w-[150px]">
                          {item.status || "N/A"}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        <div className="overflow-x-auto max-w-[200px]">
                          {item.title || item.content || item.invoiceId}
                        </div>
                      </td>
                    </tr>
                    {expandedRows[index] &&
                      item.type === "Appointment" &&
                      item.fullAppointment.history &&
                      item.fullAppointment.history.length > 0 &&
                      item.fullAppointment.history.map((historyItem) => (
                        <tr key={historyItem._id} className="bg-gray-100">
                          <td className="whitespace-nowrap py-2 pl-8 pr-3 text-sm text-gray-500 sm:pl-0"></td>
                          <td className="whitespace-nowrap py-2 pl-8 pr-3 text-sm text-gray-500 sm:pl-0">
                            <div className="overflow-x-auto"> </div>
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            <div className="overflow-x-auto">
                              {new Date(historyItem.timestamp).toLocaleString()}
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            <div className="overflow-x-auto">{`${
                              historyItem.user?.firstName || "N/A"
                            } ${historyItem.user?.lastName || ""}`}</div>
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            <div className="overflow-x-auto">
                              {historyItem.status}
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {historyItem.comments || "N/A"}
                          </td>
                        </tr>
                      ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CondensedTable;
