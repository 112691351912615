import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchClientJournal } from "../../store/thunks/clientJournalThunk";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import AppointmentSummary from "../../applicationUi/components/appointments/AppointmentSummary";
import { setSelectedAppointment } from "../../store/slices/appointmentsSlice";
import CondensedTable from "../../applicationUi/components/CondensedTable";

function ClientJournal({ client }) {
  const dispatch = useDispatch();
  const { journal, status } = useSelector((state) => state.clientJournal);
  const selectedAppointment = useSelector(
    (state) => state.appointments.selectedAppointment
  );

  const [activeSection, setActiveSection] = useState(null);
  const [sortOrder, setSortOrder] = useState("Newest first");
  const [isAppointmentSummaryOpen, setIsAppointmentSummaryOpen] =
    useState(false);

  useEffect(() => {
    if (client._id) {
      dispatch(fetchClientJournal(client._id));
    }
  }, [dispatch, client._id]);

  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  const formatDate = (date) =>
    new Date(date).toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  const handleAppointmentClick = (appointment) => {
    console.log("received this in handleappointmentclick", appointment);
    dispatch(setSelectedAppointment(appointment));
    // setSelectedAppointment(appointment);
    setIsAppointmentSummaryOpen(true);
  };

  const handleCloseAppointmentSummary = () => {
    setSelectedAppointment(null);
    setIsAppointmentSummaryOpen(false);
  };

  const sortItems = (items, dateField) => {
    return [...items].sort((a, b) => {
      const dateA = new Date(a[dateField]);
      const dateB = new Date(b[dateField]);
      return sortOrder === "Oldest first" ? dateA - dateB : dateB - dateA;
    });
  };

  if (status === "loading") {
    return <p>Loading client journal...</p>;
  }

  if (!journal) {
    return <p>No journal data available.</p>;
  }

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <div className="flex flex-row justify-between">
        <h2 className="text-lg font-bold mb-4">
          Client Journal for {client.firstName} {client.lastName}
        </h2>

        {/* Sort Menu */}
        <div className="mb-4 flex items-center">
          <span className="text-sm font-medium text-gray-900 mr-2">
            Sort by
          </span>
          <select
            id="sortOrder"
            name="sortOrder"
            value={sortOrder}
            onChange={handleSortChange}
            className="rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm">
            <option>Oldest first</option>
            <option>Newest first</option>
          </select>
        </div>
      </div>

      <CondensedTable
        journalData={journal}
        sortOrder={sortOrder}
        onClick={handleAppointmentClick}
      />

      {/* <div className="space-y-4">
        <div>
          <div>
            <button
              className={`flex items-center justify-between w-full text-sm font-medium text-gray-900 py-2 px-4 rounded-md ring-1 ring-inset ring-gray-300 hover:ring-indigo-600 focus:ring-2 focus:ring-indigo-600 ${
                activeSection === "appointments" ? "bg-gray-100" : "bg-white"
              }`}
              onClick={() => toggleSection("appointments")}>
              <span>Appointments [{journal.appointments.length}]</span>
              {activeSection === "appointments" ? (
                <ChevronUpIcon className="h-5 w-5" />
              ) : (
                <ChevronDownIcon className="h-5 w-5" />
              )}
            </button>
          </div>
          {activeSection === "appointments" && (
            <div className="p-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
              {sortItems(journal.appointments, "start").map((appointment) => (
                <div
                  key={appointment._id}
                  className="border rounded-lg p-4 bg-white shadow-sm text-sm text-gray-800 cursor-pointer hover:ring-1 hover:ring-indigo-600"
                  onClick={() => handleAppointmentClick(appointment)}>
                  <p>
                    <strong>Title:</strong> {appointment.title}
                  </p>
                  <p>
                    <strong>Service Date:</strong>{" "}
                    {formatDate(appointment.start)}
                  </p>
                  <p>
                    <strong>Status:</strong> {appointment.status.join(", ")}
                  </p>
                  <p>
                    <strong>Clinic:</strong> {appointment.clinic?.name || "N/A"}
                  </p>
                  <p>
                    <strong>Clinician:</strong>{" "}
                    {`${appointment.clinician?.firstName || "N/A"} ${
                      appointment.clinician?.lastName || ""
                    }`}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>

        <div>
          <div>
            <button
              className={`flex items-center justify-between w-full text-sm font-medium text-gray-900 py-2 px-4 rounded-md ring-1 ring-inset ring-gray-300 hover:ring-indigo-600 focus:ring-2 focus:ring-indigo-600 ${
                activeSection === "invoices" ? "bg-gray-100" : "bg-white"
              }`}
              onClick={() => toggleSection("invoices")}>
              <span>Invoices [{journal.invoices.length}]</span>
              {activeSection === "invoices" ? (
                <ChevronUpIcon className="h-5 w-5" />
              ) : (
                <ChevronDownIcon className="h-5 w-5" />
              )}
            </button>
          </div>

          {activeSection === "invoices" && (
            <div className="p-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
              {sortItems(journal.invoices, "createdAt").map((invoice) => (
                <div
                  key={invoice._id}
                  className="border rounded-lg p-4 bg-white shadow-sm text-sm text-gray-800">
                  <p>
                    <strong>Invoice ID:</strong> {invoice.invoiceId}
                  </p>
                  <p>
                    <strong>Created At:</strong> {formatDate(invoice.createdAt)}
                  </p>
                  <p>
                    <strong>Status:</strong> {invoice.status}
                  </p>
                  <p>
                    <strong>Amount:</strong> ${invoice.amount.toFixed(2)}
                  </p>
                  <p>
                    <strong>Notes:</strong> {invoice.notes || "N/A"}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>

        <div>
          <div>
            <button
              className={`flex items-center justify-between w-full text-sm font-medium text-gray-900 py-2 px-4 rounded-md ring-1 ring-inset ring-gray-300 hover:ring-indigo-600 focus:ring-2 focus:ring-indigo-600 ${
                activeSection === "payments" ? "bg-gray-100" : "bg-white"
              }`}
              onClick={() => toggleSection("payments")}>
              <span>Payments [{journal.payments.length}]</span>
              {activeSection === "payments" ? (
                <ChevronUpIcon className="h-5 w-5" />
              ) : (
                <ChevronDownIcon className="h-5 w-5" />
              )}
            </button>
          </div>
          {activeSection === "payments" && (
            <div className="p-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
              {sortItems(journal.payments, "paymentDate").map((payment) => (
                <div
                  key={payment._id}
                  className="border rounded-lg p-4 bg-white shadow-sm text-sm text-gray-800">
                  <p>
                    <strong>Payment Method:</strong> {payment.paymentMethod}
                  </p>
                  <p>
                    <strong>Amount:</strong> ${payment.amount.toFixed(2)}
                  </p>
                  <p>
                    <strong>Payment Date:</strong>{" "}
                    {formatDate(payment.paymentDate)}
                  </p>
                  <p>
                    <strong>Status:</strong>{" "}
                    {payment.voided ? "Voided" : "Completed"}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>

        <div>
          <div>
            <button
              className={`flex items-center justify-between w-full text-sm font-medium text-gray-900 py-2 px-4 rounded-md ring-1 ring-inset ring-gray-300 hover:ring-indigo-600 focus:ring-2 focus:ring-indigo-600 ${
                activeSection === "clientNotes" ? "bg-gray-100" : "bg-white"
              }`}
              onClick={() => toggleSection("clientNotes")}>
              <span>
                Client Notes [
                {journal.clientNotes.reduce(
                  (total, note) => total + note.history.length,
                  0
                )}
                ]
              </span>
              {activeSection === "clientNotes" ? (
                <ChevronUpIcon className="h-5 w-5" />
              ) : (
                <ChevronDownIcon className="h-5 w-5" />
              )}
            </button>
          </div>
          {activeSection === "clientNotes" && (
            <div className="p-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
              {sortItems(
                journal.clientNotes.flatMap((note) => note.history),
                "modifiedAt"
              ).map((historyItem) => (
                <div
                  key={historyItem._id}
                  className="border rounded-lg p-4 bg-white shadow-sm text-sm text-gray-800">
                  <p>
                    <strong>Content:</strong> {historyItem.content}
                  </p>
                  <p>
                    <strong>Modified At:</strong>{" "}
                    {formatDate(historyItem.modifiedAt)}
                  </p>
                  <p>
                    <strong>Modified By:</strong>{" "}
                    {historyItem.modifiedBy?.firstName}{" "}
                    {historyItem.modifiedBy?.lastName}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div> */}

      {/* Appointment Summary Modal */}
      {selectedAppointment && (
        <AppointmentSummary
          open={isAppointmentSummaryOpen}
          onClose={handleCloseAppointmentSummary}
          appointment={selectedAppointment}
          onReschedule={(appt) => console.log("Reschedule:", appt)}
        />
      )}
    </div>
  );
}

export default ClientJournal;
