import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAppointmentsByClinicianAndDateRange } from "../../store/thunks/appointmentsThunk";
import {
  createPayroll,
  fetchPayrollsByClinicianStatusAndPayPeriod,
} from "../../store/thunks/payrollThunk";
import PayrollTable from "../../applicationUi/components/PayrollTable";
import CompletedPayrollTable from "../../applicationUi/components/CompletedPayrollTable";
import PayrollScheduleTable from "./PayrollSchedule";
import PayrollSlideOver from "./PayrollSlideOver";
import payrollDates from "./payrollDates";
import PayrollStats from "./PayrollStats";
import { Listbox, Switch } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

export default function ClinicianPayrollOverviewPage() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const DECIMAL_userDefaultPayPercentage = user.defaultPayPercentage / 100;

  console.log(
    "DECIMAL_userDefaultPayPercentage:",
    DECIMAL_userDefaultPayPercentage
  );

  const stateClinicianAppointments = useSelector(
    (state) => state.appointments.payrollAppointments
  );
  const stateClinicianPayrolls = useSelector(
    (state) => state.payrolls.clinicianPayroll
  );

  const [currentDate, setCurrentDate] = useState(new Date());
  const [submissionDate, setSubmissionDate] = useState(new Date());

  const [isPayrollSlideOver, setIsPayrollSlideOver] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showPayrollSchedule, setShowPayrollSchedule] = useState(false);
  const [soapNotesComplete, setSoapNotesComplete] = useState(false);
  const [invoicePaid, setInvoicePaid] = useState(false);
  const [eligibleForPayroll, setEligibleForPayroll] = useState(false);

  console.log("stateclinicianappts", stateClinicianAppointments);
  console.log("stateclinicianpayrolls", stateClinicianPayrolls);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const findCurrentPayPeriod = () => {
    let closestPeriod = payrollDates[0];
    let minDiff = Number.MAX_VALUE;

    payrollDates.forEach((period) => {
      const [startDateString, endDateString] = period.includeDates.split("-");

      // Explicitly format dates for the current year
      const startDate = new Date(Date.parse(`${startDateString.trim()}`));
      const endDate = new Date(Date.parse(`${endDateString.trim()}`));

      // Ensure start and end dates cover the full day
      endDate.setHours(23, 59, 59, 999);

      if (currentDate >= startDate && currentDate <= endDate) {
        closestPeriod = period;
        minDiff = 0;
      } else {
        const diff = Math.min(
          Math.abs(currentDate - startDate),
          Math.abs(currentDate - endDate)
        );
        if (diff < minDiff) {
          closestPeriod = period;
          minDiff = diff;
        }
      }
    });

    console.log("Current Pay Period:", closestPeriod);
    return closestPeriod;
  };

  const findNextPayPeriod = () => {
    const currentPayPeriod = findCurrentPayPeriod(); // Find the current pay period
    const currentIndex = payrollDates.findIndex(
      (period) => period.payPeriodNumber === currentPayPeriod.payPeriodNumber
    );

    if (currentIndex === -1 || currentIndex + 1 >= payrollDates.length) {
      console.error("No next pay period found.");
      return null;
    }

    const nextPayPeriod = payrollDates[currentIndex + 1]; // Return the next pay period
    console.log("Next Pay Period:", nextPayPeriod);

    return nextPayPeriod;
  };

  const [selectedPeriod, setSelectedPeriod] = useState(findCurrentPayPeriod());

  const handlePeriodChange = (selectedPeriod) => {
    setStartDate(
      new Date(`${selectedPeriod.includeDates.split("-")[0].trim()}`)
    );
    setEndDate(
      new Date(`${selectedPeriod.includeDates.split("-")[1].trim()}`)
    );

    setSelectedPeriod(selectedPeriod);
    setSubmissionDate(new Date(`${selectedPeriod.submissionDate}`));

    console.log(
      "RECEIVED THIS SELECTED PERIOD IN THE NEW HADNLER",
      selectedPeriod
    );
  };

  console.log("selected period is", selectedPeriod);
  console.log(
    "before the useeffect, startdate is",
    startDate,
    "enddate is",
    endDate
  );

  const [inCarryover, setInCarryover] = useState(false);

  // Fetch data only when `isPayrollSlideOver` is false
  useEffect(() => {
    if (!isPayrollSlideOver && selectedPeriod && user) {
      try {
        dispatch(
          fetchAppointmentsByClinicianAndDateRange({
            clinicianId: user._id,
            startDate: endDate,
          })
        );
        dispatch(
          fetchPayrollsByClinicianStatusAndPayPeriod({
            clinicianId: user._id,
            payPeriod: selectedPeriod.payPeriodNumber,
            statuses: ["Pending", "Ready", "Paid to Clinician"],
          })
        );

        setInCarryover(currentDate > submissionDate);

        if (selectedAppointment) {
          const updatedAppointment = stateClinicianAppointments.find(
            (appointment) => appointment._id === selectedAppointment._id
          );
          setSelectedAppointment(updatedAppointment);
        }
      } catch (error) {
        console.error("Fetch data error:", error);
      }
    }
  }, [selectedPeriod, startDate, endDate, dispatch, user, isPayrollSlideOver]);

  // Additional useEffect to reset the selected appointment when the slideover closes
  useEffect(() => {
    if (!isPayrollSlideOver) {
      setSelectedAppointment(null); // Or any other action needed on close
    }
  }, [isPayrollSlideOver]);

  const processingAppointments = new Set();

  const handleSaveToPayroll = async (appointment, isCarryover = false) => {
    if (processingAppointments.has(appointment._id)) {
      return;
    }

    // Add appointment to the set of currently processing appointments
    processingAppointments.add(appointment._id);

    // Check if all required fields are available in the appointment
    if (
      !appointment ||
      !appointment._id ||
      !appointment.clinician ||
      !appointment.clinician._id ||
      !appointment.invoice ||
      !appointment.invoice._id ||
      !appointment.invoice.amount
    ) {
      console.error("Invalid appointment data:", appointment);
      return;
    }

    let payrollData;

    if (isCarryover) {
      const currentPayPeriod = findCurrentPayPeriod(); // Get the current pay period
      const nextPayPeriod = findNextPayPeriod(); // Find the next pay period
      // const currentDate = new Date();
      // const submissionDate = new Date(currentPayPeriod.submissionDate);
      setSubmissionDate(new Date(currentPayPeriod.submissionDate));

      // If saving after the current pay period's submission date, use the next pay period
      if (currentDate > submissionDate) {
        payrollData = {
          appointmentId: appointment._id,
          clinicianId: appointment.clinician._id,
          invoiceId: appointment.invoice._id,
          originalAmount: appointment.invoice.amount,
          percentReceived: DECIMAL_userDefaultPayPercentage,
          payrollAmount: (
            appointment.invoice.amount * DECIMAL_userDefaultPayPercentage
          ).toFixed(2),
          payPeriod: nextPayPeriod.payPeriodNumber,
          payPeriodDates: nextPayPeriod.includeDates,
          submitBy: nextPayPeriod.submissionDate,
          payBy: nextPayPeriod.paymentDate,
          status: "Pending",
        };
      } else {
        // If saving before the submission date, use the current pay period
        payrollData = {
          appointmentId: appointment._id,
          clinicianId: appointment.clinician._id,
          invoiceId: appointment.invoice._id,
          originalAmount: appointment.invoice.amount,
          percentReceived: DECIMAL_userDefaultPayPercentage,
          payrollAmount: (
            appointment.invoice.amount * DECIMAL_userDefaultPayPercentage
          ).toFixed(2),
          payPeriod: currentPayPeriod.payPeriodNumber,
          payPeriodDates: currentPayPeriod.includeDates,
          submitBy: currentPayPeriod.submissionDate,
          payBy: currentPayPeriod.paymentDate,
          status: "Pending",
        };
      }
    } else {
      // For non-carryover appointments, use the current pay period
      const currentPayPeriod = findCurrentPayPeriod(); // Always use current period for non-carryover
      payrollData = {
        appointmentId: appointment._id,
        clinicianId: appointment.clinician._id,
        invoiceId: appointment.invoice._id,
        originalAmount: appointment.invoice.amount,
        percentReceived: DECIMAL_userDefaultPayPercentage,
        payrollAmount: (
          appointment.invoice.amount * DECIMAL_userDefaultPayPercentage
        ).toFixed(2),
        payPeriod: currentPayPeriod.payPeriodNumber || null,
        payPeriodDates: currentPayPeriod.includeDates || null,
        submitBy: currentPayPeriod.submissionDate || null,
        payBy: currentPayPeriod.paymentDate || null,
        status: "Pending",
      };
    }

    try {
      await dispatch(createPayroll(payrollData)).unwrap();
      console.log("Saved to payroll:", payrollData);
      // fetchData();
    } catch (error) {
      if (error === "Payroll entry already exists for this appointment.") {
        console.log("This appointment is already saved to payroll.");
      } else {
        console.error("Error saving to payroll:", error);
      }
    } finally {
      // Remove the appointment from the set after processing is done
      processingAppointments.delete(appointment._id);
    }
  };

  const normalizeAppointmentData = (data) => {
    // Extract the carryover payroll id and data
    const payrollId = data._id || null;
    let appointment = data.appointmentId ? data.appointmentId : data;
    let invoice = data.invoiceId ? data.invoiceId : data.invoice;

    return { payrollId, appointment, invoice };
  };

  const handleAppointmentClick = (data) => {
    const { payrollId, appointment, invoice } = normalizeAppointmentData(data);

    console.log("PayrollID:", payrollId);
    console.log("Appointment:", appointment);
    console.log("Invoice:", invoice);

    if (appointment) {
      setSelectedAppointment({ appointment, invoice, payrollId });
      // dispatch(setSelectedAppointment(appointment));
      setIsPayrollSlideOver(true);
    } else {
      console.error("Invalid appointment object:", appointment);
    }
  };

  const filterAppointments = () => {
    const unfilteredAppointments = []; // Array to store appointments that don't meet the criteria

    const appointments = stateClinicianAppointments.filter((appointment) => {
      const hasSoapNotesComplete = appointment.history.some(
        (historyItem) => historyItem.status === "Soap Notes Completed"
      );
      const hasInvoicePaid =
        appointment.invoice && appointment.invoice.status === "paid";

      if (eligibleForPayroll) {
        if (!(hasSoapNotesComplete && hasInvoicePaid)) {
          unfilteredAppointments.push(appointment); // Add to unfiltered if not eligible for payroll
          return false;
        }
        return true;
      }
      if (soapNotesComplete && !hasSoapNotesComplete) {
        unfilteredAppointments.push(appointment); // Add to unfiltered if SOAP notes not complete
        return false;
      }
      if (invoicePaid && !hasInvoicePaid) {
        unfilteredAppointments.push(appointment); // Add to unfiltered if invoice not paid
        return false;
      }
      return true;
    });

    console.log("Filtered Appointments:", appointments); // Log filtered appointments
    console.log("Unfiltered Appointments:", unfilteredAppointments); // Log unfiltered appointments
    return appointments;
  };

  const getSubmissionDate = () => {
    const currentPeriod = payrollDates.find(
      (period) => period.payPeriodNumber === selectedPeriod.payPeriodNumber
    );

    if (currentPeriod) {
      const submissionDate = new Date(currentPeriod.submissionDate);
      return submissionDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      });
    } else {
      return "N/A";
    }
  };

  const getScheduledPayDay = () => {
    const currentPeriod = payrollDates.find(
      (period) => period.payPeriodNumber === selectedPeriod.payPeriodNumber
    );

    if (currentPeriod) {
      const paymentDate = new Date(currentPeriod.paymentDate);
      return paymentDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      });
    } else {
      return "N/A";
    }
  };

  const calculatePendingAppointments = () => {
    if (inCarryover) return 0;
    return filterAppointments().filter((appointment) => {
      const hasSoapNotesComplete = appointment.history.some(
        (historyItem) => historyItem.status === "Soap Notes Completed"
      );
      const hasInvoicePaid =
        appointment.invoice && appointment.invoice.status === "paid";

      return !(hasSoapNotesComplete && hasInvoicePaid);
    }).length;
  };

  const calculateCompletedAppointments = () => stateClinicianPayrolls.length;

  const calculateCarryoverAppointments = () => {
    return inCarryover ? stateClinicianAppointments.length : 0;
  };

  // Calculate the total pending pay for appointments not yet completed or carried over
  const calculatePendingPay = () => {
    if (inCarryover) return 0;
    return stateClinicianAppointments
      .reduce((sum, appointment) => {
        const servicePrice = appointment.service.price || 0;
        const pendingPay = servicePrice * DECIMAL_userDefaultPayPercentage;
        return sum + pendingPay;
      }, 0)
      .toFixed(2);
  };

  // Calculate the total pay for completed appointments using stateClinicianPayrolls
  const calculateCompletedPay = () => {
    return stateClinicianPayrolls
      .reduce((sum, payroll) => sum + (payroll.payrollAmount || 0), 0)
      .toFixed(2);
  };

  // Calculate the carryover pay for appointments in carryover mode using stateClinicianAppointments
  const calculateCarryoverPay = () => {
    if (!inCarryover) return 0;
    return stateClinicianAppointments
      .reduce((sum, appointment) => {
        const servicePrice = appointment.service.price || 0;
        const carryoverPay = servicePrice * DECIMAL_userDefaultPayPercentage;
        return sum + carryoverPay;
      }, 0)
      .toFixed(2);
  };

  const stats = [
    {
      name: `Pay Period ${selectedPeriod.payPeriodNumber}`,
      value: `${selectedPeriod.includeDates}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Submit Appointments By",
      value: `${getSubmissionDate()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Pay Day",
      value: `${getScheduledPayDay()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Pending Appointments",
      value: `${calculatePendingAppointments()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Completed Appointments",
      value: `${calculateCompletedAppointments()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Carryover Appointments",
      value: `${calculateCarryoverAppointments()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Total Pending Pay",
      value: `$${calculatePendingPay()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Total Completed Pay",
      value: `$${calculateCompletedPay()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Total Carryover Pay",
      value: `$${calculateCarryoverPay()}`,
      change: "",
      changeType: "neutral",
    },
  ];

  const getStatus = (item) => {
    const hasSoapNotesComplete = item.history.some(
      (historyItem) => historyItem.status === "Soap Notes Completed"
    );
    const hasInvoicePaid = item.invoice && item.invoice.status === "paid";

    let statuses = [];
    if (!hasSoapNotesComplete) {
      statuses.push("SOAP Notes: Incomplete");
    }
    if (!item.invoice) {
      statuses.push("Invoice: No Invoice");
    } else if (!hasInvoicePaid) {
      statuses.push(`Invoice: ${item.invoice.status}`);
    }
    if (hasSoapNotesComplete && hasInvoicePaid) {
      statuses.push("Ready");
      // Automatically save to payroll if not already saved
      if (item.invoice && !item.invoice.isPayrollSaved) {
        const isCarryover = stateClinicianAppointments.some(
          (carryoverItem) => carryoverItem._id === item._id
        );
        handleSaveToPayroll(item, isCarryover);
      }
    }

    const statusClasses = {
      "SOAP Notes: Incomplete":
        "text-orange-700 bg-orange-50 ring-orange-600/20",
      "Invoice: No Invoice": "text-orange-700 bg-orange-50 ring-orange-600/20",
      "Invoice: created": "text-orange-700 bg-orange-50 ring-orange-600/20",
      "Invoice: sent": "text-orange-700 bg-orange-50 ring-orange-600/20",
      "Invoice: paid": "text-orange-700 bg-orange-50 ring-orange-600/20",
      "Invoice: void": "text-orange-700 bg-orange-50 ring-orange-600/20",
      Ready: "text-green-700 bg-green-50 ring-green-600/20",
    };

    return (
      <div className="flex flex-col items-center space-y-1">
        {statuses.map((status, index) => (
          <div
            key={index}
            className={classNames(
              statusClasses[status],
              "rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
            )}>
            {status}
          </div>
        ))}
      </div>
    );
  };

  const handleCloseModal = () => {
    setShowPayrollSchedule(false);
    // fetchData();
  };

  const handlePayrollSlideOverClose = () => {
    setIsPayrollSlideOver(false);
    // fetchData();
  };

  return (
    <>
      <div className="py-4">
        {/* Stats */}
        <PayrollStats stats={stats} />

        {/* Pay period selector and toggles */}
        <div className="flex space-x-4 mb-4 justify-around items-center">
          <div className="flex flex-col items-center space-y-2">
            <Listbox value={selectedPeriod} onChange={handlePeriodChange}>
              <div className="flex flex-col items-center">
                <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900 text-center">
                  Select Payroll Period
                </Listbox.Label>
                <div className="relative mt-2 w-full">
                  <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <span className="block truncate">
                      {`Pay Period ${selectedPeriod.payPeriodNumber} (${selectedPeriod.includeDates})`}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        aria-hidden="true"
                        className="h-5 w-5 text-gray-400"
                      />
                    </span>
                  </Listbox.Button>
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {payrollDates.map((period) => (
                      <Listbox.Option
                        key={period.payPeriodNumber}
                        value={period}
                        className={({ active, selected }) =>
                          `relative cursor-default select-none py-2 pl-3 pr-9 ${
                            active
                              ? "bg-indigo-600 text-white"
                              : "text-gray-900"
                          }`
                        }>
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-semibold" : "font-normal"
                              }`}>
                              {`Pay Period ${period.payPeriodNumber} (${period.includeDates})`}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </div>
              </div>
            </Listbox>
          </div>
          <div className="flex flex-col items-center space-y-2">
            <label className="block text-sm font-medium leading-6 text-gray-900 text-center">
              Soap Notes Completed
            </label>
            <Switch
              checked={soapNotesComplete}
              onChange={(value) => {
                setSoapNotesComplete(value);
                if (!value) setEligibleForPayroll(false);
              }}
              className={`relative inline-flex items-center h-6 rounded-full w-11 ${
                soapNotesComplete ? "bg-indigo-600" : "bg-gray-200"
              }`}>
              <span className="sr-only">Soap Notes Completed</span>
              <span
                className={`inline-block w-4 h-4 transform bg-white rounded-full transition-transform ${
                  soapNotesComplete ? "translate-x-6" : "translate-x-1"
                }`}
              />
            </Switch>
          </div>
          <div className="flex flex-col items-center space-y-2">
            <label className="block text-sm font-medium leading-6 text-gray-900 text-center">
              Invoice Paid
            </label>
            <Switch
              checked={invoicePaid}
              onChange={(value) => {
                setInvoicePaid(value);
                if (!value) setEligibleForPayroll(false);
              }}
              className={`relative inline-flex items-center h-6 rounded-full w-11 ${
                invoicePaid ? "bg-indigo-600" : "bg-gray-200"
              }`}>
              <span className="sr-only">Invoice Paid</span>
              <span
                className={`inline-block w-4 h-4 transform bg-white rounded-full transition-transform ${
                  invoicePaid ? "translate-x-6" : "translate-x-1"
                }`}
              />
            </Switch>
          </div>
          <div className="flex flex-col items-center space-y-2">
            <label className="block text-sm font-medium leading-6 text-gray-900 text-center">
              Eligible for payroll
            </label>
            <Switch
              checked={eligibleForPayroll}
              onChange={(value) => {
                setEligibleForPayroll(value);
                setSoapNotesComplete(value);
                setInvoicePaid(value);
              }}
              className={`relative inline-flex items-center h-6 rounded-full w-11 ${
                eligibleForPayroll ? "bg-indigo-600" : "bg-gray-200"
              }`}>
              <span className="sr-only">Eligible for Payroll</span>
              <span
                className={`inline-block w-4 h-4 transform bg-white rounded-full transition-transform ${
                  eligibleForPayroll ? "translate-x-6" : "translate-x-1"
                }`}
              />
            </Switch>
          </div>
          <div>
            <button
              type="button"
              onClick={() => setShowPayrollSchedule(!showPayrollSchedule)}
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              View Payroll Schedule
            </button>
          </div>
        </div>

        {/* Tables */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 py-4">
          {/* TABLE FOR PENDING AND CARRYOVER PAYROLL */}
          <div className="border border-gray-300 rounded-lg">
            {inCarryover ? (
              <PayrollTable
                columns={[
                  {
                    header: "Status",
                    accessor: (item) => getStatus(item),
                  },
                  {
                    header: "Client",
                    accessor: (item) =>
                      item.client
                        ? `${item.client.firstName} ${item.client.lastName}`
                        : "N/A",
                  },
                  {
                    header: "Service",
                    accessor: "service.description",
                  },
                  {
                    header: "Service Date",
                    accessor: (item) => {
                      if (item.start) {
                        const date = new Date(item.start);
                        const optionsDate = {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        };
                        const optionsTime = {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        };
                        return `${date.toLocaleDateString(
                          "en-US",
                          optionsDate
                        )}, ${date.toLocaleTimeString("en-US", optionsTime)}`;
                      } else {
                        return "N/A";
                      }
                    },
                  },
                  {
                    header: "Invoice ID",
                    accessor: (item) =>
                      item.invoice ? item.invoice.invoiceId : "No Invoice",
                  },
                  {
                    header: "Invoice Amount",
                    accessor: (item) =>
                      item.invoice
                        ? `$${item.invoice.amount.toFixed(2)}`
                        : "No Invoice",
                  },
                  {
                    header: "Invoice Status",
                    accessor: (item) =>
                      item.invoice ? item.invoice.status : "No Invoice",
                  },
                  {
                    header: "Invoice Created",
                    accessor: (item) =>
                      item.invoice
                        ? new Date(item.invoice.createdAt).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )
                        : "No Invoice",
                  },
                ]}
                data={filterAppointments()}
                onRowClick={handleAppointmentClick}
                actions={[]}
                title={`Carryover Appointments [${calculateCarryoverAppointments()}]`}
                description={`These are all your appointments that have not been completed.`}
              />
            ) : (
              filterAppointments().length > 0 && (
                <PayrollTable
                  columns={[
                    {
                      header: "Status",
                      accessor: (item) => getStatus(item),
                    },
                    {
                      header: "Client",
                      accessor: (item) =>
                        item.client
                          ? `${item.client.firstName} ${item.client.lastName}`
                          : "N/A",
                    },
                    {
                      header: "Service",
                      accessor: "service.description",
                    },
                    {
                      header: "Service Date",
                      accessor: (item) => {
                        if (item.start) {
                          const date = new Date(item.start);
                          const optionsDate = {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          };
                          const optionsTime = {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          };
                          return `${date.toLocaleDateString(
                            "en-US",
                            optionsDate
                          )}, ${date.toLocaleTimeString("en-US", optionsTime)}`;
                        } else {
                          return "N/A";
                        }
                      },
                    },
                    {
                      header: "Invoice ID",
                      accessor: (item) =>
                        item.invoice ? item.invoice.invoiceId : "No Invoice",
                    },
                    {
                      header: "Invoice Amount",
                      accessor: (item) =>
                        item.invoice
                          ? `$${item.invoice.amount.toFixed(2)}`
                          : "No Invoice",
                    },
                    {
                      header: "Invoice Status",
                      accessor: (item) =>
                        item.invoice ? item.invoice.status : "No Invoice",
                    },
                    {
                      header: "Invoice Created",
                      accessor: (item) =>
                        item.invoice
                          ? new Date(item.invoice.createdAt).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )
                          : "No Invoice",
                    },
                  ]}
                  data={filterAppointments()}
                  onRowClick={handleAppointmentClick}
                  actions={[]}
                  title={`Pending Appointments [${calculatePendingAppointments()}]`}
                  description={`All your pending appointments within the selected payroll period. Submit these by ${getSubmissionDate()} to be paid on ${getScheduledPayDay()}.`}
                  description2={`After ${getSubmissionDate()}, any incomplete appointment will be marked as carryover.`}
                />
              )
            )}
          </div>

          {/* TABLE FOR COMPLETED PAYROLL */}
          <div className="border border-gray-300 rounded-lg">
            <CompletedPayrollTable
              clinicianId={user._id}
              columns={[
                {
                  header: "Payroll Status",
                  accessor: (item) => `${item.status}`,
                },
                {
                  header: "Client",
                  accessor: (item) =>
                    item.appointmentId && item.appointmentId.client
                      ? `${item.appointmentId.client.firstName} ${item.appointmentId.client.lastName}`
                      : "N/A",
                },
                {
                  header: "Service",
                  accessor: (item) =>
                    item.appointmentId && item.appointmentId.title
                      ? item.appointmentId.title
                      : "N/A",
                },
                {
                  header: "Service Date",
                  accessor: (item) => {
                    if (item.appointmentId && item.appointmentId.start) {
                      const date = new Date(item.appointmentId.start);
                      const optionsDate = {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      };
                      const optionsTime = {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      };
                      return `${date.toLocaleDateString(
                        "en-US",
                        optionsDate
                      )}, ${date.toLocaleTimeString("en-US", optionsTime)}`;
                    } else {
                      return "N/A";
                    }
                  },
                },
                {
                  header: "Billed Amount",
                  accessor: (item) =>
                    item.originalAmount != null
                      ? `$${item.originalAmount.toFixed(2)}`
                      : "N/A",
                },
                {
                  header: "% Received",
                  accessor: (item) => `${item.percentReceived}%`,
                },
                {
                  header: "Payroll Amount",
                  accessor: (item) =>
                    item.payrollAmount != null
                      ? `$${item.payrollAmount.toFixed(2)}`
                      : "N/A",
                },
              ]}
              data={stateClinicianPayrolls}
              onRowClick={(item) => handleAppointmentClick(item)}
              title={`Completed Appointments [${calculateCompletedAppointments()}]`}
              description={`All your completed appointments within the selected payroll period. These will be paid out on ${getScheduledPayDay()}.`}
            />
          </div>
        </div>
      </div>

      {showPayrollSchedule && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={handleCloseModal}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Escape") handleCloseModal();
          }}>
          <div
            className="bg-white rounded-lg shadow-lg p-8 max-w-4xl w-full relative"
            onClick={(e) => e.stopPropagation()}>
            <button
              onClick={handleCloseModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
              <XMarkIcon className="h-6 w-6" />
            </button>
            <PayrollScheduleTable />
          </div>
        </div>
      )}

      {isPayrollSlideOver && selectedAppointment && (
        <PayrollSlideOver
          isOpen={isPayrollSlideOver}
          appointment={selectedAppointment}
          onClose={handlePayrollSlideOverClose}
          selectedPeriod={selectedPeriod.payPeriodNumber}
          selectedPeriodDates={selectedPeriod.includeDates}
          submitBy={selectedPeriod.submissionDate}
          payBy={selectedPeriod.paymentDate}
          // onUpdateSuccess={fetchData} // Pass fetchData as onUpdateSuccess
          // refetchInvoices={refetchInvoices}
        />
      )}
    </>
  );
}
