"use client";

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserById, fetchUserPicture } from "../../store/thunks/userThunk";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import {
  CalendarIcon,
  DocumentTextIcon,
  CheckIcon,
  ClockIcon,
} from "@heroicons/react/20/solid";
import { format } from "date-fns";
import { updateAppointment } from "../../store/thunks/appointmentsThunk";
import { toast } from "react-toastify";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function StepsVerticalListV2({ appointment }) {
  const dispatch = useDispatch();
  const selectedAppointment = useSelector(
    (state) => state.appointments.selectedAppointment || appointment
  );
  const userProfilePicture = useSelector((state) => state.users.profilePicture);
  const user = useSelector((state) => state.auth.user);
  // console.log("user", user);

  const [historyWithUsers, setHistoryWithUsers] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [pictureCache, setPictureCache] = useState({});

  // console.log("pictureCache", pictureCache);

  const fetchPicture = async (userId) => {
    console.log("fetchPicture", userId, pictureCache);
    // Check if picture is already in cache
    if (pictureCache[userId]) {
      return pictureCache[userId];
    }

    // If not in cache, fetch and store it
    const result = await dispatch(fetchUserPicture({ userId }));
    const pictureUrl = result.payload || "https://via.placeholder.com/150";

    // Update cache with new picture
    setPictureCache((prev) => ({
      ...prev,
      [userId]: pictureUrl,
    }));
    return pictureUrl;
  };

  useEffect(() => {
    const fetchUsers = async () => {
      const updatedHistory = await Promise.all(
        selectedAppointment.history.map(async (entry) => {
          // console.log("in stepsVerticalList, this is the entry", entry);
          if (entry.user && typeof entry.user === "string") {
            try {
              const userDetails = await dispatch(
                fetchUserById(entry.user)
              ).unwrap();
              let profilePicture = await fetchPicture(entry.user);
              if (!profilePicture) {
                profilePicture = "https://via.placeholder.com/150";
              }
              // console.log("profilePicture", profilePicture);
              return {
                ...entry,
                user: userDetails,
                profilePicture,
              };
            } catch (error) {
              console.error(
                `Error fetching user details for ID ${entry.user}:`,
                error
              );
            }
          }
          return entry;
        })
      );
      setHistoryWithUsers(updatedHistory);
    };

    fetchUsers();
  }, [selectedAppointment.history, dispatch]);

  function formatDate(timestamp) {
    return format(new Date(timestamp), "MMM dd, yyyy HH:mm");
  }

  const handleSaveComment = async () => {
    console.log("newComment", newComment);
    if (!newComment.trim()) return;

    const updatedHistory = [
      ...selectedAppointment.history,
      {
        status: "Comments",
        comments: newComment,
        timestamp: new Date().toISOString(),
        user: user._id,
      },
    ];

    const updatedAppointment = {
      ...selectedAppointment,
      history: updatedHistory,
    };

    console.log("History with users", historyWithUsers);

    try {
      await dispatch(
        updateAppointment({
          id: selectedAppointment._id,
          updatedData: updatedAppointment,
        })
      ).unwrap();
      setNewComment("");
      toast.success("Comment added successfully");
    } catch (error) {
      console.log("Comment failed to add", error);
      toast.error("Failed to add comment");
    }
  };

  return (
    <>
      <ul className="space-y-6">
        {historyWithUsers.map((activityItem, activityItemIdx) => (
          <li key={activityItem.id} className="relative flex gap-x-4">
            <div
              className={classNames(
                activityItemIdx === historyWithUsers.length - 1
                  ? "h-6"
                  : "-bottom-6",
                "absolute left-0 top-0 flex w-6 justify-center"
              )}>
              <div className="w-px bg-gray-200" />
            </div>
            {activityItem.status === "Comments" ? (
              <>
                <img
                  alt=""
                  src={activityItem.profilePicture}
                  className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
                />
                <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                  <div className="flex justify-between gap-x-4">
                    <div className="py-0.5 text-xs leading-5 text-gray-500">
                      <span className="font-medium text-gray-900">
                        {activityItem.user.firstName}{" "}
                        {activityItem.user.lastName}
                      </span>{" "}
                      commented
                    </div>
                    <time
                      dateTime={activityItem.timestamp}
                      className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                      {formatDate(activityItem.timestamp)}
                    </time>
                  </div>
                  <p className="text-sm leading-6 text-gray-500">
                    {activityItem.comments}
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                  {activityItem.status === "Paid" ? (
                    <CheckCircleIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-indigo-600"
                    />
                  ) : activityItem.status === "Booked" ? (
                    <CalendarIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-blue-500"
                    />
                  ) : activityItem.status === "Invoiced" ? (
                    <DocumentTextIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-yellow-500"
                    />
                  ) : activityItem.status === "Invoice Paid" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                      className="h-6 w-6 text-green-500">
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm.31-8.86c-1.77-.45-2.34-.94-2.34-1.67 0-.84.79-1.43 2.1-1.43 1.38 0 1.9.66 1.94 1.64h1.71c-.05-1.34-.87-2.57-2.49-2.97V5H10.9v1.69c-1.51.32-2.72 1.3-2.72 2.81 0 1.79 1.49 2.69 3.66 3.21 1.95.46 2.34 1.15 2.34 1.87 0 .53-.39 1.39-2.1 1.39-1.6 0-2.23-.72-2.32-1.64H8.04c.1 1.7 1.36 2.66 2.86 2.97V19h2.34v-1.67c1.52-.29 2.72-1.16 2.73-2.77-.01-2.2-1.9-2.96-3.66-3.42z" />
                    </svg>
                  ) : activityItem.status === "Confirmed" ? (
                    <CheckIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-green-500"
                    />
                  ) : activityItem.status === "Tentative" ? (
                    <ClockIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-orange-500"
                    />
                  ) : activityItem.status === "Treated" ? (
                    <CheckCircleIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-purple-500"
                    />
                  ) : (
                    <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                  )}
                </div>
                <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                  <span className="font-medium text-gray-900">
                    {activityItem.user?.firstName} {activityItem.user?.lastName}
                  </span>{" "}
                  {(() => {
                    switch (activityItem.status) {
                      case "Tentative":
                        return "marked the Appointment as Tentative.";
                      case "Invoice Paid":
                        return "took payment for the invoice.";
                      case "Soap Notes Saved":
                        return "Saved a SOAP Note";
                      case "Soap Notes Updated":
                        return "Updated a SOAP Note";
                      case "Soap Notes Completed":
                        return "Completed a SOAP Note";
                      case "Soap Notes Approved":
                        return "Approved a SOAP Note";
                      case "Invoice Posted to QuickBooks":
                        return "Invoice Posted to QuickBooks";
                      default:
                        return `${activityItem.status} the Appointment.`;
                    }
                  })()}
                </p>
                <time
                  dateTime={activityItem.dateTime}
                  className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                  {formatDate(activityItem.timestamp)}
                </time>
              </>
            )}
          </li>
        ))}
      </ul>

      {/* New comment form */}
      <div className="mt-6 flex gap-x-3">
        <img
          alt=""
          src={userProfilePicture}
          className="h-6 w-6 flex-none rounded-full bg-gray-50"
        />
        <div className="relative flex-auto">
          <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
            <label htmlFor="comment" className="sr-only">
              Add your comment
            </label>
            <textarea
              id="comment"
              name="comment"
              rows={2}
              placeholder="Add your comment..."
              className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
            />
          </div>

          <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
            <button
              onClick={handleSaveComment}
              className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              Comment
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
